html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.container {
  margin-top: 25px;
  max-width: 90% !important;
}

.table {
  min-width: 992px;
}

@media (min-width: 992px) {
  .table {
    min-width: unset;
  }
}
